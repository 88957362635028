<template>
  <footer :class="`theme-footer-seven ${dark_bg ? 'dark-bg pt-120 md-pt-100' : 'mt-120 md-mt-100'}`">
    <div class="lg-container">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-3 col-lg-2 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <div
              v-if="!dark_bg"
              class="logo"
            ><router-link to="/">
              <img
                :src="require(`@/assets/images/logo/heubot_9.svg`)"
                alt=""
              ></router-link>
            </div>
            <div
              v-else
              class="logo"
            ><router-link to="/">
              <img
                :src="require(`@/assets/images/logo/heubot_9.svg`)"
                alt=""
              ></router-link>
            </div>
          </div>
          <div
            class="col-lg-2 col-md-6 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <h5 class="title">
              Links
            </h5>
            <ul class="footer-list">
              <li><a href="/">Home</a></li>
              <li><a href="/privacy">Privacy</a></li>
              <li><a href="/contact">Contact Us</a></li>
              <li><a href="/feedback">Feedback</a></li>
            </ul>
          </div>
          <div
            class="col-lg-3 col-md-6 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            <h5 class="title">
              Legal
            </h5>
            <ul class="footer-list">
              <li><a href="/comingsoon">Terms of use</a></li>
              <li><a href="/terms">Terms & conditions</a></li>
              <li><a href="/privacy">Privacy policy</a></li>
            </ul>
          </div>
          <div
            class="col-xl-4 col-lg-5 mb-40"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <div class="newsletter">
              <h5 class="title">
                Newsletter
              </h5>
              <p>Join the growing number of people getting our emails</p>
              <form @submit.prevent="onSubmit">
                <input
                  type="email"
                  placeholder="Enter your email"
                >
                <button :class="dark_btn ? 'dark-btn' : ''">
                  Sign Up
                </button>
              </form>
              <p> <a
                href="https://storyset.com/work"
                style="color:black;"
              >Illustrations by Storyset</a></p>
            </div> <!-- /.newsletter -->
          </div>
        </div>
      </div>

      <div class="container">
        <div class="bottom-footer">
          <div class="row">
            <div class="col-lg-4 order-lg-1 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-start footer-nav">
                <li><a href="#">Privacy & Terms.</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-3 mb-20">
              <ul class="d-flex justify-content-center justify-content-lg-end social-icon">
                <li><a href="#"><i
                  class="fa fa-facebook"
                  aria-hidden="true"
                /></a></li>
                <li><a href="#"><i
                  class="fa fa-twitter"
                  aria-hidden="true"
                /></a></li>
                <li><a href="#"><i
                  class="fa fa-linkedin"
                  aria-hidden="true"
                /></a></li>
              </ul>
            </div>
            <div class="col-lg-4 order-lg-2 mb-20">
              <p class="copyright text-center">
                Copyright @2022 heu inc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- /.lg-container -->

  </footer>
</template>

<script>
export default {
  name: 'FooterSeven',
  props: {
    dark_btn: Boolean,
    dark_bg: Boolean,
  },
  methods: {
    onSubmit() {
    },
  },
}
</script>
