<template>
  <div
    id="feature"
    class="fancy-feature-seventeen lg-container mt-150 md-mt-90 "
  >
    <div class="container up-a-bit">
      <div class="row">
        <div
          class="col-lg-10 m-auto"
          data-aos="fade-up"
          data-aos-duration="1200"
          :data-aos-delay="100"
        >
          <div class="title-style-thirteen text-center mb-130 md-mb-90">
            <div class="upper-title">
              Features
            </div>
            <h2><span> Heu <img
              :src="require(`@/assets/images/shape/line-shape-13.svg`)"
              alt=""
            ></span>is our solution to <span>cognitive bias in writings</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="bottom-border pb-50">
        <div class="row">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            :data-aos-delay="item.delay"
          >
            <div class="block-meta">
              <div class="icon d-flex align-items-end">
                <img
                  :src="item.icon"
                  alt=""
                >
              </div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.subtitle }}</p>
            </div> <!-- /.block-meta -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyFeature',
  data() {
    return {
      featureData: [
        {
          // eslint-disable-next-line global-require
          id: 1, icon: require('@/assets/images/icon/94.svg'), title: 'State Of The Art', subtitle: 'Built with the latest AI techniques to detect biases in written text', delay: 0,
        },
        {
          // eslint-disable-next-line global-require
          id: 2, icon: require('@/assets/images/icon/95.svg'), title: 'Your AI-Assistant', subtitle: 'Confidently navigate cognitive minefields with your own AI-powered assistant', delay: '100',
        },
        {
          // eslint-disable-next-line global-require
          id: 3, icon: require('@/assets/images/icon/96.svg'), title: 'Build Confidence', subtitle: 'Trained on billions of texts to ensure most common cognitive biases are detected ', delay: '200',
        },
      ],
    }
  },
}
</script>
<style>
.up-a-bit {
  /* margin-top: -200px !important; */
}
</style>
