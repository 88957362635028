<template>
  <div class="main-page-wrapper font-gordita overflow-hidden">
    <Header />
    <WelcomeBanner />
    <UseCases />
    <Interface />
    <Problem />
    <Solution />
    <Counter />
    <CounterArea />
    <FancyFeatureTwo />
    <FancyFeature3 />
    <Value />
    <PricingArea />
    <ShortBanner />
    <Footer />
  </div>
</template>

<script>
import Header from './Header.vue'
import WelcomeBanner from './WelcomeBanner.vue'
import Interface from './Interface.vue'
// import UseCases from './UseCases.vue'
import Problem from './Problem.vue'
import Solution from './Solution.vue'
// import Counter from '../components/counter/CounterTwo.vue'
// import CounterArea from './CounterArea.vue'
import Value from './Value.vue'
// import PricingArea from "./Pricing-area.vue";
import ShortBanner from './ShortBanner.vue'
import Footer from './Footer.vue'

export default {
  components: {
    Header,
    WelcomeBanner,
    Interface,
    Problem,
    // UseCases,
    // FancyFeature3,
    // CounterArea,
    // Counter,
    Solution,
    Value,
    // PricingArea,
    ShortBanner,
    Footer,
  },
  data() {
  },
}
</script>
<style scoped>
@import 'css/custom.css';
@import 'css/style.css';
@import 'css/custom-animation.css';
@import 'css/framework.css';
@import 'css/menu.css';
@import 'css/responsive.css';
</style>
