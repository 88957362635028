<template>
  <div
    id="p-tour"
    class="fancy-feature-twentyNine lg-container mt-150 pt-20 md-mt-70"
  >
    <div class="container">

      <div class="block-style-thirty">
        <div class="row">
          <div
            class="col-xl-5 col-lg-6 order-lg-last"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="100"
          >
            <div class="text-block md-pb-60">
              <div class="feature">
                READING
              </div>
              <div class="title-style-nine mb-45 md-mb-30">
                <h2>Read Confidently.</h2>
              </div>
              <p>Heu is always on the search for the most common biases as you read.</p>
              <ul class="feature-list-two">
                <li>
                  Helps identify them so you make <span> better decisions</span>
                </li>
                <li>
                  Shows you your <span>most common</span> biases.
                </li>
                <li>
                  Provides details on how to <span>avoid</span> them.
                </li>
              </ul>
              <!-- <a
                href="#"
                class="theme-btn-nine mt-30"
              >Learn More</a> -->
            </div> <!-- /.text-block -->
          </div>
          <div
            class="col-xl-7 col-lg-6 order-lg-first"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-delay="100"
          >
            <img
              :src="require(`@/assets/images/assets/readingoutside.svg`)"
              alt=""
              width="80%"
              height="100%"
            >
          </div>
        </div>
      </div> <!-- /.block-style-thirty -->

      <div class="mt-80 pb-100 md-pb-80 bottom-border">
        <div class="row justify-content-between">

          <div
            v-for="item in block_styles"
            :key="item.id"
            class="col-xl-3 col-md-4"
            data-aos="fade-up"
            :data-aos-delay="item.delay"
          >
            <div class="block-style-twentyEight text-center mt-40">
              <div class="icon d-flex justify-content-center align-items-end">
                <img
                  :src="item.icon"
                  alt=""
                >
              </div>
              <h4>{{ item.title }}</h4>
              <p>{{ item.subtitle }}</p>
            </div> <!-- /.block-style-twentyEight -->
          </div>

        </div>
      </div>

      <div class="block-style-thirty mt-150 md-mt-90">
        <div class="row">
          <div
            class="col-xl-5 col-lg-6"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-delay="100"
          >
            <div class="text-block md-pb-60">
              <div class="feature">
                WRITING
              </div>
              <div class="title-style-nine mb-45">
                <h2>Compose Boldly.</h2>
              </div>
              <p class="pb-30">
                Be assured that your writings are free of unwanted bias.
              </p>
              <ul class="feature-list-two">
                <li>
                  Helps you clarify <span>ideas.</span>
                </li>
                <li>
                  Shows your <span>most common</span> biases.
                </li>
              </ul>
              <div>
                <!--         <a
                  href="/app"
                  class="theme-btn-nine"
                >Try it now: It's Free</a> -->
              </div>
            </div> <!-- /.text-block -->
          </div>
          <div
            class="col-xl-7 col-lg-6"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-delay="100"
          >
            <img
              :src="require(`@/assets/images/assets/write.svg`)"
              alt=""
              width="80%"
              height="100%"
            >
          </div>
        </div>
      </div> <!-- /.block-style-thirty -->

    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyFeature',
  data() {
    return {
      feature_list_one: [
        {
          // eslint-disable-next-line global-require
          id: 1, icon: require('@/assets/images/icon/157.svg'), title: '35,000', subtitle: 'The average adult makes an estimated 35,000 decisions per day.',
        },
        {
          // eslint-disable-next-line global-require
          id: 2, icon: require('@/assets/images/icon/158.svg'), title: '175', subtitle: 'There are 175+ known biases and researchers find new ones all the time',
        },
        {
          // eslint-disable-next-line global-require
          id: 3, icon: require('@/assets/images/icon/159.svg'), title: '80', subtitle: 'Research shows that 80% of people see themselves as above average.',
        },
      ],
    }
  },
}
</script>
