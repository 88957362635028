<template>
  <div class="hero-banner-seven lg-container">
    <div class="container">
      <div class="illustration-container">
        <img
          :src="require(`@/assets/images/assets/writer.svg`)"
          alt=""
          width="80%"
          height="120%"
        >
      </div>
      <div class="row">
        <div class="col-lg-9">
          <p
            class="hero-sub-heading "
            style="padding: 0px"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            Meet Heu,
            <!--  <img
              :src="require(`@/assets/images/assets/ils_20.svg`)"
              alt=""
              width="20px"
              height="20px"
            > -->
          </p>
          <h1
            class="hero-heading font-gordita-bold"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            Detect
          </h1>
          <h1
            class="hero-heading font-gordita-bold"
            style="color:#E9C46A;"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            cognitive bias
          </h1>
          <h1
            class="hero-heading font-gordita-bold"
            data-aos="fade-right"
            data-aos-delay="400"
          >
            in <span>writings</span>.
          </h1>
          <p
            class="hero-sub-heading"
            data-aos="fade-right"
            data-aos-delay="500"
          >
            Why only spellcheck for grammar, check for bias too!
          </p>

          <a
            href="/app"
            target="_blank"
            class="theme-btn-nine"
          >Try it now</a>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'HeroArea',
  components: {
  },
  data() {
    return {
      breakpoints: {
        0: {
          itemsToShow: 2,
          snapAlign: 'left',
        },
        576: {
          itemsToShow: 3,
          snapAlign: 'left',
        },
        768: {
          itemsToShow: 4,
          snapAlign: 'left',
        },
        992: {
          itemsToShow: 5,
          snapAlign: 'left',
        },
      },
    }
  },
  methods: {
    onSubmit() {
    },
  },
}
</script>
