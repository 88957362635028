<template>
  <div
    id="p-tour"
    class="fancy-feature-twentyNine lg-container mt-150 pt-20 md-mt-70"
  >
    <div class="container">
      <div class="block-style-thirty bottom-border pb-80 mb-170 md-pb-40 md-mb-90">
        <div class="row">
          <div
            class="col-xl-5 col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <div class="text-block md-pb-60">
              <div class="feature">
                BIAS BY THE NUMBER
              </div>
              <div class="title-style-nine mb-60 md-mb-40">
                <h2>The Impact of Cognitive Bias</h2>
              </div>
              <p>The science is clear: Humans take mental shortcuts know as <span style="color: #E9C46A; font-weight:bold">cognitive biases </span>. These have consequential effects on the way we think, feel, and behave. </p>
              <ul class="feature-list-one">
                <li
                  v-for="list in feature_list_one"
                  :key="list.id"
                >
                  <img
                    :src="list.icon"
                    alt=""
                    class="icon"
                  >
                  <strong>{{ list.title }}</strong>
                  <span>{{ list.subtitle }}</span>
                </li>
                <!--                <a
                  href="https://thedecisionlab.com/biases"
                  class="theme-btn-nine mt-20"
                >Learn More</a> -->
              </ul>
            </div> <!-- /.text-block -->
          </div>
          <div
            class="col-xl-7 col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="100"
          >
            <img
              :src="require(`@/assets/images/assets/biasimpact.png`)"
              alt=""
            >
          </div>
        </div>
      </div> <!-- /.block-style-thirty -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyFeature',
  data() {
    return {
      feature_list_one: [
        {
          // eslint-disable-next-line global-require
          id: 1, icon: require('@/assets/images/icon/157.svg'), title: '35,000', subtitle: 'The average adult makes an estimated 35,000 decisions per day and a majority will be impacted negatively by bias.',
        },
        {
          // eslint-disable-next-line global-require
          id: 2, icon: require('@/assets/images/icon/158.svg'), title: '188', subtitle: 'There are 188+ known biases and researchers find new ones all the time.',
        },
        {
          // eslint-disable-next-line global-require
          // id: 3, icon: require('@/assets/images/icon/159.svg'), title: '80', subtitle: 'Research shows that 80% of people see themselves as above average.',
        },
      ],
    }
  },
}
</script>
