<template>
  <div>
    <div class="hero-banner-five bottom-border">
      <div class="img-gallery">
        <div class="container text-center">
          <div class="screen-container">
            <img
              :src="require(`@/assets/images/assets/Hero1.png`)"
              alt=""
              class="main-screen"
            >
            <img
              :src="require(`@/assets/images/assets/features.png`)"
              alt=""
              class="shapes screen-one"
            >
            <img
              :src="require(`@/assets/images/assets/Detects.png`)"
              alt=""
              class="shapes screen-two"
            >
          </div> <!-- /.screen-container -->

          <img
            :src="require(`@/assets/images/shape/133.svg`)"
            alt=""
            class="shapes shape-one"
          >
          <img
            :src="require(`@/assets/images/shape/134.svg`)"
            alt=""
            class="shapes shape-two"
          >
          <img
            :src="require(`@/assets/images/shape/135.svg`)"
            alt=""
            class="shapes shape-three"
          >
          <img
            :src="require(`@/assets/images/shape/136.svg`)"
            alt=""
            class="shapes shape-four"
          >
        </div>
      </div> <!-- /.img-gallery -->

    </div>
  </div>
</template>

<script>

export default {
  name: 'HeroArea',
  components: {
  },
  data() {
    return {
      breakpoints: {
        0: {
          itemsToShow: 2,
          snapAlign: 'left',
        },
        576: {
          itemsToShow: 3,
          snapAlign: 'left',
        },
        768: {
          itemsToShow: 4,
          snapAlign: 'left',
        },
        992: {
          itemsToShow: 4,
          snapAlign: 'left',
        },
        1200: {
          itemsToShow: 5,
          snapAlign: 'left',
        },
      },
    }
  },
}
</script>
