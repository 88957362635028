<template>
  <div class="fancy-short-banner-eight pt-170 md-pt-80">

    <div class="container">
      <div class="row">

        <div class="col-xl-7 m-auto">
          <div
            class="title-style-seven text-center"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2><span>Try it Now </span></h2>
            <p class="pt-45 pb-50 text-dark">
              Confidently navigate cognitive minefields with your own AI-powered assistant.
            </p>
            <a
              href="#"
              class="theme-btn-nine"
            >Web Application</a><a
              href="#"
              class="theme-btn-home"
            >Get the Chrome Extension</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortBanner',
}
</script>
