<template>
  <div
    :class="`theme-main-menu sticky-menu theme-menu-five ${
      isSticky ? 'fixed' : ''
    }`"
  >
    <div class="d-flex align-items-center justify-content-center">
      <div class="logo">
        <router-link to="/">
          <img
            :src="require(`@/assets/images/logo/heubot_13.svg`)"
            alt=""
          >
        </router-link>
      </div>

      <nav
        id="mega-menu-holder"
        class="navbar navbar-expand-lg"
      >
        <div class="nav-container">
          <button
            class="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
            :class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
            @click="menuActive = !menuActive"
          >
            <span />
          </button>
          <div
            id="collapsibleNavbar"
            :class="
              menuActive
                ? 'navbar-collapse collapse ss'
                : 'navbar-collapse collapse'
            "
          >
            <div class="d-lg-flex justify-content-between align-items-center">
              <ul
                id="one-page-nav"
                class="navbar-nav main-side-nav font-gordita"
              >
                <li class="nav-item">
                  <a
                    href="/"
                    class="nav-link"
                  ><span
                    @click.self="menuActive = !menuActive"
                  >Home</span></a>
                </li>
                <li class="nav-item">
                  <a
                    href="/privacy"
                    class="nav-link"
                  ><span
                    @click.self="menuActive = !menuActive"
                  >Privacy</span></a>
                </li>
                <li class="nav-item">
                  <a
                    href="/Contact"
                    class="nav-link"
                  ><span
                    @click.self="menuActive = !menuActive"
                  >Contact</span></a>
                </li>
                <li class="nav-item">
                  <a
                    href="/feedback"
                    class="nav-link"
                  ><span
                    @click.self="menuActive = !menuActive"
                  >Feedback</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div class="right-widget">
        <ul class="d-flex align-items-center">

          <!--   <li>
            <a
              href="/app"
              target="_blank"
              class="theme-btn-home"
            >Chrome Extension</a>
          </li> -->
          <li>
            <a
              href="/app"
              target="_blank"
              class="theme-btn-nine"
            >Try it now: It's Free</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderSeven',
  components: {
  },
  data() {
    return {
      isSticky: false,
      menuActive: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleSticky)
  },
  methods: {
    handleSticky() {
      if (window.scrollY > 80) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
  },
}
</script>
<style scoped>
.theme-btn-nine {
  font-family: "gordita";
  font-weight: bold;
  line-height: 58px;
  border-radius: 50px;
  color: #fff !important;
  padding: 0 34px;
  min-width: 180px;
  text-align: center;
  background: #262626;
  transition: all 0.3s ease-in-out;
}
 .theme-btn-nine:hover {
  background: #ffeaa0 !important;
  color: #212121 !important;
}
</style>
